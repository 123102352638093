.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .logo {
        animation: logo-spin infinite 2s linear;
    }
}

@keyframes logo-spin {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(360deg);
    }
}

.table > div > div > div:nth-child(odd) {
    background-color: #13195a;
}

#list-team > div:nth-child(5n) {
    margin-right: 11%;
}

#list-team > div:nth-child(5n +1) {
    margin-left: inherit;
}

.Mui-selected {
    color: white !important;
}

.Mui-focusVisible {
   color: white !important;
}

.MuiTabs-indicatorSpan {
    background-color: white !important;
}

.cup{
    width: 30%;
}

.cup2 {
    width: 20%;
}

.cupView {
    width: 60%;
}

.cupView2 {
    width: 40%;
}

.cupView3 {
    width: 10%;
}